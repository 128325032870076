module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sendity","description":"Envios de dinero, recargas y pago de facturas a Latinoamérica | Sendity: tus pagos, ¡de una!","display":"standalone","lang":"es","icons":[{"src":"icons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"icons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"cache_busting_mode":"none","icon_options":{"purpose":"maskable"},"short_name":"Sendity","start_url":"/","theme_color":"#fffeee","background_color":"#000000","localize":[{"start_url":"/en/","lang":"en","name":"Sendity","short_name":"Sendity","description":"Send money, recharges and payments to Latin America | Sendity: easy payments, connected families."}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"mode":"async","preconnect":["https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans"],"web":[{"name":"Jakarta","file":"https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
