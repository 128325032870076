import React, { Children, Suspense, cloneElement, lazy } from "react";

import { AddToHomeScreenButton } from "../addToHomeScreenButton/AddToHomeScreenButton";
import { Header } from "./Header";
import { Footer } from "./Footer";

import "./layout.css";

import "../../styles/bootstrap.css";
import "../../styles/style.css";
import "../../styles/responsive.css";
import "../../styles/slick-theme.css";
import "../../styles/slick.css";

const CookieConsent = lazy(() => import("../Cookies"));
export const Layout = ({ children, location, lang }) => {
  const { pathname } = location;
  const isHome = pathname === "/" || pathname === "/en/";

  const allowedUrls = ["/enviar-dinero", "/en/send-money"];

  const isIframePage =
    pathname === "/calculadora/" || pathname === "/calculadora-recargas/";

  const isBannerAllowed =
    isHome || allowedUrls.find((url) => pathname.includes(url));

  if (isIframePage) {
    return (
      <main>
        <Suspense fallback={null}>
          {Children.map(children, (child, index) =>
            cloneElement(child, { key: index, lang }),
          )}
        </Suspense>
      </main>
    );
  }
  return (
    <>
      <AddToHomeScreenButton />
      <Header
        location={location}
        isHome={isHome}
        lang={lang}
        bannerDisplay={isBannerAllowed}
      />
      <main>
        <Suspense fallback={null}>
          {Children.map(children, (child, index) =>
            cloneElement(child, { key: index, lang }),
          )}
        </Suspense>
      </main>
      <Footer lang={lang} location={location} />

      <Suspense fallback={null}>
        <CookieConsent lang={lang} location={location} />
      </Suspense>
    </>
  );
};
