import React, { Fragment } from "react";
import { Link } from "gatsby";
import "./langSwitch.css";

const langArr = [
  { lng: "es", path: "/", language: "Español" },
  { lng: "en", path: "/en/", language: "English" },
];
const LangSwitch = ({
  isHome,
  name,
  path,
  target,
  classes,
  orientation = "down",
  location,
}) => {
  const selectedLang = location.pathname.split("/").includes("en")
    ? "english"
    : "español";

  return (
    <Fragment>
      <li className="langmenu_main">
        <span
          className="langmenu_selected-lang"
          style={{
            "--custom-color": isHome ? "#000" : "#fff",
            fontWeight: "300",
          }}
        >
          {selectedLang}
          <span className="sr-only">{selectedLang}</span>
        </span>

        <ul
          className="langmenu_menu-list"
          style={{
            marginTop: orientation === "up" ? "-110px" : "8px",
          }}
        >
          {langArr.map(({ lng, path, language }) => (
            <li key={lng}>
              <Link to={path}>{language}</Link>
            </li>
          ))}
        </ul>
      </li>
      {name && path ? (
        <li className={classes}>
          <a href={path} target={target}>
            {name}
          </a>
        </li>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export { LangSwitch };
