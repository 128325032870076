import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { LangSwitch } from "../LangSwitch";
import "./footer.css";

export const Footer = ({ lang, location }) => {
  const data = useStaticQuery(graphql`
    query StaticFooter {
      allStrapiFooter {
        nodes {
          locale
          menu_column {
            title
            classes
            menu_elements {
              name
              path
              id
            }
          }
        }
      }
    }
  `).allStrapiFooter.nodes.filter(({ locale }) => locale === lang)[0];

  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-in d-flex flex-wrap">
            <div className="foot-lft col-lg-8 col-md-12">
              <div className="links-in d-flex flex-wrap">
                {data.menu_column.map((val, index) => {
                  return (
                    <div className={val.classes} key={index}>
                      <b>{val.title}</b>
                      <ul className="clearfix">
                        {val.menu_elements.map((valu, indexu) => {
                          return (
                            <li key={indexu} className={valu.classes}>
                              <Link
                                to={valu.path}
                                target={valu.target}
                                role="menuitem"
                              >
                                {valu.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="foot-rgt col-lg-4 col-md-12">
              <Link to="/">
                <StaticImage
                  placeholder="blurred"
                  alt="powered by sendity logo"
                  src="./poweredBySendityLogo.webp"
                  height={55}
                  width={197}
                />
              </Link>
              <div className="sc-links">
                <ul className="clearfix d-flex flex-wrap">
                  <li className="col-3">
                    <a
                      href="https://facebook.com/sendityapp/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        placeholder="blurred"
                        alt="facebook logo"
                        src="../../../images/RRSS/facebook.webp"
                        height={20}
                        width={11}
                      />
                    </a>
                  </li>
                  <li className="col-3">
                    <a
                      href="https://www.youtube.com/channel/UCHmC0BHD5VJwlVV-S3Y0WLQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        placeholder="blurred"
                        alt="youtube logo"
                        src="../../../images/RRSS/youtube.webp"
                        height={17}
                        width={24}
                      />
                    </a>
                  </li>
                  <li className="col-3">
                    <a
                      href="https://www.linkedin.com/company/sendity/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        placeholder="blurred"
                        alt="linkedin logo"
                        src="../../../images/RRSS/linkedin.webp"
                        height={20}
                        width={20}
                      />
                    </a>
                  </li>
                  <li className="col-3">
                    <a
                      href="https://instagram.com/appsendity"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        placeholder="blurred"
                        alt="instagram logo"
                        src="../../../images/RRSS/instagram.webp"
                        height={20}
                        width={20}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="foot-btm">
        <div className="container">
          <nav className="foot-btm-in">
            <ul
              className="d-flex flex-wrap clearfix pl-0 align-items-center"
              style={{ paddingLeft: 0 }}
            >
              {lang === "es" ? (
                <div className="textfooter">
                  <p>
                    Sendity opera bajo licencia de RevoluPAY EP S.L.U. RevoluPAY
                    EP es Entidad de Pagos autorizada,
                  </p>
                  <p>
                    supervisada por el Banco de España e inscrita en el Registro
                    de Entidades con número de licencia 6900. Sendity tiene su
                    domicilio social en la calle Pajaritos, 24, Madrid 28007.
                  </p>
                </div>
              ) : (
                <div className="textfooter">
                  <p>
                    Sendity operates under license from RevoluPAY EP S.L.U.
                    RevoluPAY EP is an authorized Payment Entity,
                  </p>
                  <p>
                    supervised by the Bank of Spain and registered in the
                    Registry of Entities with license number 6900. Sendity has
                    its registered office at Calle Pajaritos, 24, Madrid 28007.
                  </p>
                </div>
              )}
              <div className="footer-static_sub-container">
                <LangSwitch orientation="up" location={location} />
              </div>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
